import React from "react"
import styled from "styled-components"

import { Container, Section } from "../global"
import SignupForm from '../signup-form';

export default function GetStarted() {
  return <StyledSection id="requestademo">
    <GetStartedContainer>
      <SignupForm />
    </GetStartedContainer>
  </StyledSection>
}

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
