import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Section, Container } from "../global"

const Walkthrough = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(sourceInstanceName: { eq: "product" }, name: { eq: "walkthrough1" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image2: file(sourceInstanceName: { eq: "product" }, name: { eq: "walkthrough2" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image3: file(sourceInstanceName: { eq: "product" }, name: { eq: "walkthrough3" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);

  return <Section>
    <Container>
      <Flex>
        <HeaderTextGroup>
          <h1>Upload</h1>
          <h2>Drag and drop your files to upload them to StudyBox</h2>
        </HeaderTextGroup>
        <ImageWrapper>
          <StyledImage fluid={data.image1.childImageSharp.fluid} />
        </ImageWrapper>
      </Flex>
      <Flex>
        <HeaderTextGroup>
          <h1>Share</h1>
          <h2>Students see shared files instantly</h2>
        </HeaderTextGroup>
        <ImageWrapper>
          <StyledImage fluid={data.image2.childImageSharp.fluid} />
        </ImageWrapper>
      </Flex>
      <Flex>
        <HeaderTextGroup>
          <h1>Preview and Download</h1>
          <h2>View photos and videos in your browser, or download them and view them offline</h2>
        </HeaderTextGroup>
        <ImageWrapper>
          <StyledImage fluid={data.image3.childImageSharp.fluid} />
        </ImageWrapper>
      </Flex>
    </Container>
  </Section>;
}

export default Walkthrough;

const HeaderTextGroup = styled.div`
  margin: 0;
  h1 {
    margin: 24px;
    color: ${props => props.theme.color.primary};
    text-align: center;
  }
  h2 {
    ${props => props.theme.font_size.regular};
    text-align: center;
    margin: 40px;
  }
`;

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  margin: 40px 0;
  align-items: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`;

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  margin-right: 60px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
    margin: 0;
  }
`

const StyledImage = styled(Img)`
  width: 400px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 300px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
  }
`
