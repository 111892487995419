import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="features">
    <Container>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Works with your LMS</FeatureTitle>
          <FeatureText>
            StudyBox works with D2L Brightspace, Canvas, Moodle, Blackboard, and any LMS that supports LTI.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Private and Secure</FeatureTitle>
          <FeatureText>
            Your files are encrypted and stored in Amazon S3.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </Container>
  </Section>
)

export default Features

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
`

const FeatureText = styled.p`
  text-align: center;
`
