import React, { useCallback, useState } from "react"
import styled from "styled-components"
import ReCAPTCHA from "react-google-recaptcha";

export default function SignupForm() {
  const [state, setState] = useState('start');
  const [email, setEmail] = useState();
  const [captcha, setCaptcha] = useState();

  const submit = useCallback(() => {
    async function post() {
      await fetch('/api/signup', {
        method: 'post',
        body: JSON.stringify({ email, captcha }),
        headers: { 'Content-Type': 'application/json' },
      });
      setState('done');
      sendConversion();
    }
    post();
  }, [email, captcha]);

  function renderChallenge() {
    return <>
      <Centered>
        Thanks for your interest! Kindly confirm you are human before we send your request.
      </Centered>
      <ReCAPTCHA
        sitekey="6Ld2xFYUAAAAAL-lNyO4rwACZRuu-GPjOA4wbQSc"
        onChange={value => setCaptcha(value)}
      />
      <ChallengeButton onClick={submit}>Submit</ChallengeButton>
    </>
  }

  function renderForm() {
    return <>
      <h2>
        We'd love to hear from you
      </h2>
      <Centered>
        Our mission is to help you get the most out of your LMS.<br/>
        Contact us if you'd like to learn more.
      </Centered>
      <Centered subtle={true}>We promise not to spam you or share your email with anyone</Centered>
      <HeaderForm onSubmit={(e) => e.preventDefault()}>
        <HeaderInput onChange={e => setEmail(e.target.value)} placeholder="Your email" type="email" />
        <HeaderButton onClick={() => setState('challenge')}>Request a demo</HeaderButton>
      </HeaderForm>
    </>;
  }

  function renderDone() {
    return <>
      <Centered>
        Thanks for your message! We will contact you soon and look forward to helping you get the most out of your LMS.
      </Centered>
    </>;
  }

  function sendConversion() {
    window && window.gtag && window.gtag(
      'event', 'conversion', {
        'send_to': 'AW-993153917/0cdHCJWg_ssBEP2mydkD'
      }
    );
  }

  return <>
    { state === 'start' && renderForm() }
    { state === 'challenge' && renderChallenge() }
    { state === 'done' && renderDone() }
  </>;
}

const Centered = styled.p`
  text-align: center;
  color: ${props => props.subtle ? props.theme.color.subtle : 'inherit'};
  ${props => props.subtle ? props.theme.font_size.xsmall : props.theme.font_size.regular};
`;

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  line-height: 42px;
  min-width: 300px;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  margin-bottom: ${props => props.full ? '8px' : 0};
  &:focus {
    box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  &:invalid {
    box-shadow: inset ${props => props.theme.color.error} 0px 0px 0px 2px;
    border-color: ${props => props.theme.color.error};
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`;

const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: ${props => props.full ? 0 : '8px'};
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`;

const ChallengeButton = styled(HeaderButton)`
  margin-top: 20px;
`;
